import React, { useEffect, useState } from 'react';
import styles from "./Home.css";
import axios from 'axios';

import mainLogoWall from "../Assests/wallLogo.png";
import image_raji from "../Assests/picCont_Raji.jpg";
import image_ajay from "../Assests/picAjay.jpg";
import image_vijayvirPic from "../Assests/vijayvirPic.jpeg";

import image_awardKisan from "../Assests/awardKisan.jpg";
import image_picRF_news from "../Assests/picRF_news.jpg";

import certficateAward from "../Assests/certficateAward.jpg";

import { TextBanner } from "./TextBanner.js";
import { TextBannerPotato } from "./TextBannerPotato.js";
import Pau from "./Pau.js";
import NitrogenCalculator from "./NitrogenCalculator.js";

import ImageSliderOwner from "./ImageSliderOwner.js"
import PagerClass from "./PagerClass.js";

import { Workshop } from "./Workshop.js";

import { ImageClass } from "./ImageClass.js";

import {
  RFarm_Title_2,
  RFarm_Title,
  RFarm_poplarTileTitle_2,
  LifeMemberPau,
  RFarm_Title_2Com,
} from "./GerenicView.js";


export default function Home() {
  const [ipAddress, setIpAddress] = useState('');

  useEffect(() => {

    const getIpAddress = async () => {
      try {
        const response = await axios.get('/getIPAddress');
        setIpAddress(response.data.ip);
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };

    getIpAddress();
  }, []);




  return (
    <>
      <div className="mainContainerHome">
        <TextBanner />
        {/* <TextBannerPotato/> */}
        {/* <div>
      <p>User's IP Address: {ipAddress}</p>
    </div> */}

        <p>
          < ImageSliderOwner className="p_imageContainer_left" />
          <RFarm_Title /> established in the year <b>1989</b> by <LifeMemberPau />) in
          the region of Ropar. <RFarm_poplarTileTitle_2 /> grows Poplar Nursery,
          Potato, Onion ,Rice , Turmic (Haldi), Poplar tree and other seasonal
          crops. It’s the first farm who have started the Poplar farming in the
          Region of Ropar. Till now it has satisfied Thousands of clients and
          created Hundreds of Success Stories in the Area. <RFarm_Title_2 />
          was awarded with different award from PAU and other communities
          <ImageClass src={image_raji} className="p_imageContainer_right" />
           {" "}related to farming. <RFarm_Title_2 /> flow in productive approach and
          ready to follow new approaches and experiments related to farming
          sectors. With experience of 30+ year, Farms follow the best practise
          for the growing of Nursery.
        </p>
        <div>
          <h3>
            Preparing of Poplar Nursery at <RFarm_Title_2 />:
          </h3>
          We follow best practice. Seedling are treaded with fungicies and
          pesticides after testing the soil. Proper spacing are provide
          (Vertically and Horizontally) within the new seedlings, so that proper
          growth can get. In whole year farm workers do 7 + Pruning by skilled
          hands , making sure plants don't get wounds. In end of year, each
          plant is colored based on the clones it is, making sure even piece
          don't get mixed with other clones'. In the season of summer,
          <RFarm_Title_2 /> sure that Nursery don't die due to shortage of
          water. At Transplanting months <RFarm_Title_2 /> take precautions that
          clients seedlings roots are in water in special diged channels till
          time of disptaching from farm.
        </div>

        <div>
          <h3>
            Clones that <RFarm_Title_2 /> produces:
          </h3>
          <ImageClass src={certficateAward} className="p_imageContainer_left" />
        </div>
        <p>
          Currently At <RFarm_Title_2 /> we served in following Clones.
          <ul className="aa">
            <li> W-109</li>
            <li> W-110</li>
            <li> W-112</li>
            <li> W-113</li>
            <li>WSL-39</li>
            {/* <li>PL-2 </li>
            <li> OTHE</li>
            <li> A-22</li> */}
          </ul>
        </p>
        <ImageClass src={image_picRF_news} className="p_imageContainer_right" />
        <p>
          <h3>
            <RFarm_Title_2 /> provides following facilities :
          </h3>
          <ul className="aa">
          <li>Best Quality Clones.</li>
            <li>Cost of single plant is Rs 20-30(* ).</li>
            <li>Transport available at reasonable rate.</li>
            <li>Labour for planting is provided by expert workers.</li>
            <li> Options to harvest own choice plants from the field.</li>
            <li>Different height clones are available.</li>

            <li>Clones are kept in water from the field in the supervision of staff</li>
          </ul>
        </p>

        <div>
          <h2>
            Other Services at <RFarm_Title_2 />
          </h2>
          <ul className="aa">
            <li>Potato Seedlings and Rashan </li>
            <li>
              Sant Kartar Singh computerised Dharam Kanda(7*24) - Max load 60
              Tons
            </li>
            <li>Delux Washing Station</li>
            <li>Raji Saw Mill</li>
            <li>Raji Feed store</li>
            <li>Website and App Designs by Sister Company</li>
          </ul>
        </div>

        <div>
          <h2>
            {" "}
            Pruning Technique by <RFarm_Title_2 />
          </h2>
          Gurdip Singh Raji, observed new pattern of pruning, which are fully
          practical and experiment on different clients plots. In the first year
          of new plots of tree, pruning are done in special way. As usally
          farmer do pruning upto 15-20 feet for all branches from its start of
          branch. But in new techniqe branches are cut from 10 inches from start
          point. In next year when new leaves grows , these left branches , new
          branches are also grow to them. as a result it helps in more growth in
          trees and which results in increase it diameter more than Traditional
          Pruning. Also in Second years its recommended that these left branches
          and other top branches are cut from its start point.
        </div>
        <br />
        <div>
          <h2>
            General Steps for Poplar Transplanting by{" "}
            <RFarm_poplarTileTitle_2 />
          </h2>

          <h5>
            {" "}
            Farmer should take following precautions and guidences at the time
            of Transplanting of Poplar Seedling.
          </h5>
          <ImageClass src={image_ajay} className="p_imageContainer_left" />
     
          <ul className="aa">
            <li>
              Planting of Poplar in water channels: Make channels at the time of
              wheat sowing to irrigate poplars during wheat maturity and
              harvesting. It will not reduce growth of plants due to water
              stress.
            </li>

            <li>
              Fertilizer Requirement of Poplar Plantations: The fertilizer dose,
              timing and its method of application to poplar plantations during
              their different growth years were standardized for obtaining
              higher productivity from poplar based agroforestry system. In
              medium N soils, it is recommended to apply 80, 120, 160, 200, 240
              and 280 g/plant N during 1st, 2nd, 3rd, 4th, 5th and 6th year of
              poplar growth, respectively. In low N soils, apply 25% more and in
              high N soils apply 25% less N than these doses.
            </li>
            <li>
              Time of Application: Every year, apply 1/3rd N during May after
              harvesting of wheat, 1/3rd N in July and the remaining 1/3rd N in
              September. There is no need of P application to poplar every year.
            </li>
         
            <li>
              It is suggested, depth of hole is alteast 1 Meter (3 Feet). If
              plant lenght is more Farmer can dig the hole more than 1 Meter as
              per requirement.
            </li>
            <li>
              It is suggested, Farmer should water the plants root after adding
              some Soil in hole and then fill remaing hole with soil. In this
              way plant get sufficent water for its growth. It is optional to
              add fertilizers at the of transplating, as leaves will come after
              2 months.
            </li>
            <li>
              {" "}
              It is suggested, If labour is provided by <RFarm_Title_2 />, Any
              member from client should be there in the plots. So that he can
              verify the work done by workers and guide them as per Client
              plots.
            </li>
            <li>
              It is suggested, If farmer has bought the seedings but plot is not
              ready for transplating, make sure root of each plant is in water.
              so that plant should not dried.{" "}
            </li>

            <li>
              It is suggested to farmers , If labour is from <RFarm_Title_2 />,
              make sure your plots are not wet so that workers task dont stop
              due to it or notify <RFarm_Title_2 /> so that it can be
              rescheduled to other day by farm.
            </li>
            <ImageClass src={image_vijayvirPic} className="p_imageContainer_left" />
            <li>
              
              It is suggested to farmers , If labour is from <RFarm_Title_2 />,
              and stay for nights at your farm, make sure you provide them wood,
              light on your motor.
            </li>
  
            <li>
              It is suggested to farmers, If you have your own Vehicle for
              transporting the seedlings from <RFarm_Title_2 /> , It must have
              minimum lenght and heigth so that all of yours seedling can load
              to it or dont face any problem while driving. Secondly farmer
              should have enough rope to tie the load so that it don't slip
              while driving.
              
            </li>
            
          </ul>
        
        </div>

        <div>
          <PagerClass />
        </div>

        <hr></hr>

        <div>
          <Workshop />
        </div>
     
        <div>
          <Pau />
        </div>

        <div>
         <NitrogenCalculator/> 
        </div>

       
        <div className="">
      <h1 className="h1Headng"> Multiple intercrops in  Poplar Tree</h1>
      <div className="subContainerTable">
        <table>
        <tr>
        <th> ਸਾਲ </th>
       <th>  ਸਾਉਣੀ [Summer]  </th>
       <th> ਹਾੜੀ [Winter]</th>
       <th>  ਸਾਲਾਨਾ [Yearly]   </th>
        </tr>

        <tr>
        <td> 1</td>
       <td>  ਮੈਂਥਾ [Mint], ਮੂੰਗੀ  [mung bean] , ਮੱਕੀ [Corn], ਚਰੀ [Sorghum] , ਅਰਬੀ  [taro] </td>
       <td> ਕਣਕ [Wheat], ਆਲੂ [Potato] , ਗੇਂਦਾ  , ਸਰੋਂ , vegetables </td>
       <td>  ਹਲਦੀ, ਗੰਨਾ   </td>
        </tr>
        
        <tr>
        <td> 2</td>
       <td>  ਮੈਂਥਾ, ਮੂੰਗੀ , ਮੱਕੀ, ਚਰੀ , ਅਰਬੀ , ਬਾਜਰਾ, ਰਵਾਂਹ   </td>
       <td> ਕਣਕ, ਆਲੂ , ਗੇਂਦਾ , ਸਰੋਂ,ਬਰਸੀਮ , ਜਵੀ </td>
       <td>  ਹਲਦੀ, ਗੰਨਾ   </td>
        </tr>


        <tr>
        <td> 3-4</td>
       <td>  ਅਰਬੀ , ਬਾਜਰਾ, ਰਵਾਂਹ   </td>
       <td> ਕਣਕ, ਆਲੂ , ਗੇਂਦਾ , ਸਰੋਂ,ਬਰਸੀਮ , ਜਵੀ </td>
       <td>  ਹਲਦੀ  </td>
        </tr>

        <tr>
        <td> 5-6</td>
       <td>  ---  </td>
       <td> ਕਣਕ, ਆਲੂ , ਗੇਂਦਾ , ਸਰੋਂ,ਬਰਸੀਮ , ਜਵੀ </td>
       <td>  --  </td>
        </tr>

        </table>
      </div>
    </div>



      </div>
    </>
  );
}
