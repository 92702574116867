import React from 'react'
import styles from "./GerenicView.css";


 function RFarm_Title() {
  return (
    <span className= "rajiFarmTile1_Span"> Raji Farm </span> 
  )
}

function LifeMemberPau() {
  return (
    <span className= "lifeMember_Span">S.Gurdip Singh Raji (Ex Sarpanch of Bhaku majra, Ropar Punjab , Life member P.A.U Kisan Club - Ludhiana) </span> 
  )
}

function RFarm_Title_2Com() {
  return (
    <span className= "rajiFarmTile2_Span"> Raji Farm.com </span> 
  )
}


  function RFarm_Title_2() {
    return (
      <span className= "rajiFarmTile2_Span"> Raji Farm </span> 
    )
  }
  
  function RFarm_poplarTileTitle_2() {
    return (
      <span className= "rajiFarm_poplarTile2_Span"> Raji Poplar Farm </span> 
    )
  }

  export {RFarm_Title_2, RFarm_Title,RFarm_poplarTileTitle_2 , LifeMemberPau ,RFarm_Title_2Com };



